<template>
  <base-modal
    :show="visible"
    modal-classes="modal-lg"
    @close="onClose"
  >
    <template #header>
      <h5>Release Notes</h5>
    </template>
    <template #content>
      <div v-html="$sanitize(releaseNotesToHtml)" />
    </template>
    <template #footer>
      <div class="col text-center">
        <button
          class="btn btn-square btn-square"
          @click="onClose"
        >
          Close
        </button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { BaseModal } from "~/components/Library/UIComponents/";
import { mapStores } from "pinia";
import { marked } from "marked";
import { useActionStore } from "~/stores/action";
import { useUserStore } from "~/stores/user";

export default {
  name: "ReleaseNotesModal",
  components: {
    BaseModal,
  },
  emits: ["delete", "update", "save"],
  data() {
    return {
      releaseNotes: "<p>Release notes</p>",
      mode: "create",
      booking: {},
      resource: {},
      visible: false,
      message: "",
      pickerOptions: {
        firstDayOfWeek: 1,
      },
    };
  },
  computed: {
    ...mapStores(useActionStore, useUserStore),
    releaseNotesToHtml() {
      return this.releaseNotes;
    },
  },
  async mounted() {
    const data = await $fetch("/releases/v4.2.0.md");
    // TODO: load json and then markdown
    this.releaseNotes = marked.parse(data.replace(/\\n/g, "\n"));
  },
  methods: {
    show() {
      this.visible = true;
    },
    onClose() {
      this.userStore.acknowledge_current_version();
      this.visible = false;
    },
  },
};
</script>
