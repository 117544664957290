<template>
  <div>
    <div :class="{ 'nav-open': sidebarStore.showSidebar }">
      <release-notes-modal ref="releaseNotesModal" />
      <side-bar
        :sidebar-links="sidebarStore.filtered_links"
        type="sidebar"
      />
      <div class="main-panel">
        <top-navbar />
        <div
          class="content"
          @click="sidebarStore.toggleSidebar"
        >
          <el-alert
            v-if="is_superuser"
            :closable="false"
            effect="dark"
            type="error"
          >
            You are a SuperUser!
          </el-alert>
          <el-alert
            v-if="show_release_notes"
            close-text="Read release notes"
            effect="dark"
            type="error"
            @close="showReleaseNotes"
          >
            New version released! <a />
          </el-alert>
          <el-alert
            v-for="(alert, index) in alerts.items"
            :key="index"
            :close-text="alert.closeText"
            :title="alert.message"
            class="alert-message"
            effect="dark"
            type="warning"
            @close="alert.handler"
          />
          <transition
            mode="out-in"
            name="fade"
          >
            <div v-if="loaded">
              <slot />
            </div>
          </transition>
        </div>
        <content-footer @show-release-notes="showReleaseNotes" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapStores } from "pinia";
import ContentFooter from "~/components/general/Footer.vue";
import ReleaseNotesModal from "~/components/ReleaseNotesModal.vue";
import { Sidebar } from "~/components/sidebar";
import TopNavbar from "~/components/general/Navbar.vue";
import { useAlertStore } from "~/stores/alert";
import { useSidebarStore } from "~/stores/sidebar";
import { useUserStore } from "~/stores/user";

export default {
  name: "AuthenticatedLayout",
  components: {
    ReleaseNotesModal,
    TopNavbar,
    ContentFooter,
    "side-bar": Sidebar,
  },
  setup() {
    return { user: useUserStore(), alerts: useAlertStore() };
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapStores(useSidebarStore),
    ...mapState(useSidebarStore, {
      sidebarLinks: "filtered_links",
    }),
    is_superuser() {
      return this.user.is_superuser();
    },
    show_release_notes() {
      return !this.user.has_acknowledged_current_version();
    },
  },
  mounted() {
    this.loaded = true;
  },
  methods: {
    showReleaseNotes() {
      this.$refs.releaseNotesModal.show();
    },
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.el-alert {
  margin-bottom: 5px;
}

.alert-message .el-alert__content {
  flex-direction: row;
  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
  .el-alert__close-btn {
    position: inherit;
    text-align: center;
  }
}
</style>
